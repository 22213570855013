var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('base-data-table',{attrs:{"show-expand":"","single-expand":"","auto-expand-first":"","title":_vm.$t('categories'),"item-key":"iCategoryId","headers":_vm.headers,"actions":_vm.actions,"provider":_vm.$mainCategories,"expanded":_vm.expanded,"fetch-function":_vm.getCategories},on:{"update:expanded":function($event){_vm.expanded=$event},"view":_vm.onViewCategory,"update":_vm.onOpenUpdateModal,"delete":_vm.onConfirmDeleteCategory,"click:add":_vm.onAddCategory},scopedSlots:_vm._u([{key:"item.serial",fn:function(ref){
var index = ref.index;
return [_vm._v(" "+_vm._s(_vm.$mainCategories.perPage * (_vm.$mainCategories.currentPage - 1) + index + 1)+" ")]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"tw-text-AA tw-capitalize tw-text-sm tw-font-semibold"},[_vm._v(" "+_vm._s(item.status)+" ")])]}},{key:"item.type",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"tw-capitalize"},[_vm._v(" "+_vm._s(item.type)+" ")])]}},{key:"item.isMain",fn:function(){return [_c('v-chip',{attrs:{"small":"","color":"success"}},[_vm._v(" "+_vm._s(_vm.$t('main'))+" ")])]},proxy:true},{key:"item.name",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('div',{staticClass:"tw-flex tw-space-x-2 tw-items-center"},[_c('v-img',{ref:"cImgRef",staticClass:"tw-flex-none tw-rounded-none",attrs:{"alt":item.name,"src":_vm.$image(item.image),"width":"44","height":"44"},on:{"error":function($event){return _vm.$onImgError({ index: index, ref: _vm.$refs.cImgRef })}}}),_c('div',[_vm._v(_vm._s(item.name))])],1)]}},{key:"expanded-item",fn:function(ref){
var item = ref.item;
return [_c('v-card',{attrs:{"outlined":""}},[_c('v-card-title',[_c('div',[_c('span',[_vm._v(_vm._s(_vm.$t('childs-of')))]),_c('strong',{staticClass:"tw-ml-1"},[_vm._v(" "+_vm._s(item.name))])]),_c('v-spacer'),_c('v-btn',{staticClass:"tw-font-ave tw-normal-case tw-font-medium",attrs:{"large":"","rounded":"","depressed":"","color":"primary"},on:{"click":function($event){return _vm.onAddSubCategory(item)}}},[_vm._v(" "+_vm._s(_vm.$t('add-sub-category'))+" ")])],1),_c('v-card-text',[(item.childCategories && item.childCategories.length)?_c('v-simple-table',{attrs:{"dense":""},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticClass:"text-left"},[_vm._v(_vm._s(_vm.$t('serial')))]),_c('th',{staticClass:"text-left"},[_vm._v(_vm._s(_vm.$t('is-main')))]),_c('th',{staticClass:"text-left"},[_vm._v(" "+_vm._s(_vm.$t('categoryName'))+" ")]),_c('th',{staticClass:"text-left"},[_vm._v(_vm._s(_vm.$t('type')))]),_c('th',{staticClass:"text-left"},[_vm._v(_vm._s(_vm.$t('parent')))]),_c('th',{staticClass:"text-left"},[_vm._v(" "+_vm._s(_vm.$t('headers.actions'))+" ")])])]),_c('tbody',_vm._l((item.childCategories),function(subCategory,sIndex){return _c('tr',{key:subCategory.id},[_c('td',[_vm._v(_vm._s(sIndex + 1))]),_c('td',[_c('v-chip',{attrs:{"small":"","color":"info"}},[_vm._v(" "+_vm._s(_vm.$t('subCategory'))+" ")])],1),_c('td',[_c('div',{staticClass:"tw-flex tw-space-x-2 tw-items-center"},[_c('img',{ref:"sCatImgRef",refInFor:true,staticClass:"tw-flex-none tw-rounded-none",attrs:{"width":"44","height":"44","alt":subCategory.name,"src":_vm.$image(subCategory.image)},on:{"error":function($event){return _vm.$onImgError({
														index: sIndex,
														ref: _vm.$refs.sCatImgRef,
													})}}}),_c('div',[_vm._v(_vm._s(subCategory.name))])])]),_c('td',[_c('v-chip',{staticClass:"tw-capitalize"},[_vm._v(" "+_vm._s(subCategory.type)+" ")])],1),_c('td',[_vm._v(_vm._s(item.name))]),_c('td',[_c('base-table-actions',{attrs:{"actions":_vm.actions,"item":subCategory},on:{"view":_vm.onViewCategory,"update":_vm.onOpenUpdateModal,"delete":_vm.onConfirmDeleteCategory}})],1)])}),0)]},proxy:true}],null,true)}):_c('div',{staticClass:"tw-font-ave tw-text-primary tw-text-center tw-py-12 tw-text-lg"},[_vm._v(" "+_vm._s(_vm.$t('no-sub-categories-found'))+" ")])],1)],1)]}}],null,true)}),_c('DialogDeleteConfirmation',{attrs:{"hide-id":"","title":_vm.$t('category'),"id":_vm.confirmDialog.category,"delete-function":_vm.deleteCategoryById},model:{value:(_vm.confirmDialog.show),callback:function ($$v) {_vm.$set(_vm.confirmDialog, "show", $$v)},expression:"confirmDialog.show"}},[(_vm.deleteWaring.length)?_c('v-card',{staticClass:"tw-mt-5 tw-text-left warning--text tw-rounded-xl tw-border-yellow-500",attrs:{"outlined":""}},[_c('v-card-title',{staticClass:"tw-items-center"},[_c('v-icon',{staticClass:"tw-me-1",attrs:{"color":"warning"}},[_vm._v("mdi-alert")]),_c('span',[_vm._v(_vm._s(_vm.$t('category-used-in')))])],1),_c('v-card-subtitle',{staticClass:"pb-0"},[_vm._v(_vm._s(_vm.$t('if-delete-category-dependant')))]),_c('v-card-text',[_c('v-simple-table',{staticClass:"tw-mt-3"},[_c('thead',[_c('tr',[_c('th',[_vm._v("NS")]),_c('th',[_vm._v(_vm._s(_vm.$t('module')))]),_c('th',[_vm._v(_vm._s(_vm.$t('count')))])])]),_c('tbody',_vm._l((_vm.deleteWaring),function(item,index){return _c('tr',{key:index},[_c('td',[_vm._v(_vm._s(index + 1))]),_c('td',[_vm._v(_vm._s(item[0].replace('Count', '')))]),_c('td',[_vm._v(_vm._s(item[1]))])])}),0)])],1)],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }