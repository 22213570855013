<template>
	<div>
		<!-- single-expand -->
		<base-data-table
			show-expand
			single-expand
			auto-expand-first
			:title="$t('categories')"
			item-key="iCategoryId"
			:headers="headers"
			:actions="actions"
			:provider="$mainCategories"
			:expanded.sync="expanded"
			:fetch-function="getCategories"
			@view="onViewCategory"
			@update="onOpenUpdateModal"
			@delete="onConfirmDeleteCategory"
			@click:add="onAddCategory"
		>
			<template v-slot:[`item.serial`]="{ index }">
				{{
					$mainCategories.perPage * ($mainCategories.currentPage - 1) +
					index +
					1
				}}
			</template>

			<!-- STATUS -->
			<template v-slot:[`item.status`]="{ item }">
				<div class="tw-text-AA tw-capitalize tw-text-sm tw-font-semibold">
					{{ item.status }}
				</div>
			</template>

			<!-- TYPE -->
			<template v-slot:[`item.type`]="{ item }">
				<v-chip class="tw-capitalize"> {{ item.type }} </v-chip>
			</template>

			<template v-slot:[`item.isMain`]>
				<v-chip small color="success"> {{ $t('main') }} </v-chip>
			</template>

			<!-- NAME -->
			<template v-slot:[`item.name`]="{ item, index }">
				<div class="tw-flex tw-space-x-2 tw-items-center">
					<v-img
						:alt="item.name"
						:src="$image(item.image)"
						width="44"
						height="44"
						ref="cImgRef"
						class="tw-flex-none tw-rounded-none"
						@error="$onImgError({ index, ref: $refs.cImgRef })"
					/>
					<div>{{ item.name }}</div>
				</div>
			</template>

			<!-- EXPANDED ITEM -->
			<template v-slot:expanded-item="{ item }">
				<v-card outlined class="">
					<v-card-title>
						<div>
							<span>{{ $t('childs-of') }}</span>
							<strong class="tw-ml-1"> {{ item.name }}</strong>
						</div>

						<v-spacer></v-spacer>

						<v-btn
							large
							rounded
							depressed
							color="primary"
							@click="onAddSubCategory(item)"
							class="tw-font-ave tw-normal-case tw-font-medium"
						>
							{{ $t('add-sub-category') }}
						</v-btn>
					</v-card-title>
					<v-card-text>
						<v-simple-table
							v-if="item.childCategories && item.childCategories.length"
							dense
						>
							<template v-slot:default>
								<thead>
									<tr>
										<th class="text-left">{{ $t('serial') }}</th>
										<th class="text-left">{{ $t('is-main') }}</th>
										<th class="text-left">
											{{ $t('categoryName') }}
										</th>
										<th class="text-left">{{ $t('type') }}</th>
										<th class="text-left">{{ $t('parent') }}</th>
										<th class="text-left">
											{{ $t('headers.actions') }}
										</th>
									</tr>
								</thead>
								<tbody>
									<tr
										v-for="(
											subCategory, sIndex
										) in item.childCategories"
										:key="subCategory.id"
									>
										<td>{{ sIndex + 1 }}</td>
										<td>
											<v-chip small color="info">
												{{ $t('subCategory') }}
											</v-chip>
										</td>
										<td>
											<div
												class="tw-flex tw-space-x-2 tw-items-center"
											>
												<img
													width="44"
													height="44"
													ref="sCatImgRef"
													class="tw-flex-none tw-rounded-none"
													:alt="subCategory.name"
													:src="$image(subCategory.image)"
													@error="
														$onImgError({
															index: sIndex,
															ref: $refs.sCatImgRef,
														})
													"
												/>
												<div>{{ subCategory.name }}</div>
											</div>
										</td>
										<td>
											<v-chip class="tw-capitalize">
												{{ subCategory.type }}
											</v-chip>
										</td>
										<td>{{ item.name }}</td>
										<td>
											<base-table-actions
												:actions="actions"
												:item="subCategory"
												@view="onViewCategory"
												@update="onOpenUpdateModal"
												@delete="onConfirmDeleteCategory"
											/>
										</td>
									</tr>
								</tbody>
							</template>
						</v-simple-table>
						<div
							v-else
							class="tw-font-ave tw-text-primary tw-text-center tw-py-12 tw-text-lg"
						>
							{{ $t('no-sub-categories-found') }}
						</div>
					</v-card-text>
				</v-card>
			</template>
		</base-data-table>

		<DialogDeleteConfirmation
			v-model="confirmDialog.show"
			hide-id
			:title="$t('category')"
			:id="confirmDialog.category"
			:delete-function="deleteCategoryById"
		>
			<v-card
				v-if="deleteWaring.length"
				outlined
				class="tw-mt-5 tw-text-left warning--text tw-rounded-xl tw-border-yellow-500"
			>
				<v-card-title class="tw-items-center">
					<v-icon color="warning" class="tw-me-1">mdi-alert</v-icon>
					<span>{{ $t('category-used-in') }}</span>
				</v-card-title>
				<v-card-subtitle class="pb-0">{{
					$t('if-delete-category-dependant')
				}}</v-card-subtitle>
				<v-card-text>
					<v-simple-table class="tw-mt-3">
						<thead>
							<tr>
								<th>NS</th>
								<th>{{ $t('module') }}</th>
								<th>{{ $t('count') }}</th>
							</tr>
						</thead>
						<tbody>
							<tr v-for="(item, index) in deleteWaring" :key="index">
								<td>{{ index + 1 }}</td>
								<td>{{ item[0].replace('Count', '') }}</td>
								<td>{{ item[1] }}</td>
							</tr>
						</tbody>
					</v-simple-table>
				</v-card-text>
			</v-card>
		</DialogDeleteConfirmation>

		<!-- <DialogCategory /> -->
	</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { toFormData, omitEmpties } from 'vuelpers'
import DialogDeleteConfirmation from '../../components/dialogs/DialogDeleteConfirmation.vue'

export default {
	name: 'Categories',
	components: { DialogDeleteConfirmation },
	data() {
		return {
			isDeleting: false,
			expanded: [],
			property: 'value',

			categoryForm: {
				dialog: false,
				data: {},
			},
			onView: false,
			onUpdate: false,
			confirmDialog: {
				show: false,
				category: null,
			},
		}
	},
	computed: {
		...mapGetters('category', ['$mainCategories']),
		headers() {
			return [
				{ text: this.$t('serial'), value: 'serial' },
				{ text: this.$t('is-main'), value: 'isMain' },
				{ text: this.$t('categoryName'), value: 'name' },
				{ text: this.$t('type'), value: 'type' },
				{ text: this.$t('childs'), value: 'childCategories.length' },
				{ text: this.$t('headers.actions'), value: 'actions' },
			]
		},
		actions() {
			return [
				// {
				// 	icon: 'mdi-eye',
				// 	text: 'View',
				// 	event: 'view',
				// },
				{
					icon: 'mdi-pencil',
					text: this.$t('edit'),
					event: 'update',
				},
				{
					icon: 'mdi-delete',
					text: 'delete',
					event: 'delete',
				},
			]
		},
		deleteWaring() {
			if (!this.confirmDialog.show) return []
			const counters = [
				'buyersCount',
				'offersCount',
				'suppliersCount',
				'productsCount',
				'marketingOffersCount',
			]
			return Object.entries(this.confirmDialog?.category || {}).filter(
				([key, value]) => {
					return counters.includes(key) && value > 0
				}
			)
		},
	},
	methods: {
		...mapActions('category', [
			'getCategories',
			'getCategoryById',
			'createCategory',
			'updateCategory',
			'deleteCategoryById',
			'setCategoryState',
		]),
		// onAddSubCategory(mainCategoryId = 0) {
		// 	this.initCategoryModal({
		// 		isMain: 0,
		// 		mainCategoryId,
		// 	})
		// },
		initCategoryModal(config = {}) {
			this.setCategoryState({
				create: {
					modal: true,
					isLoading: false,
					data: {
						name: '',
						isMain: 1,
						type: 'product',
						...config,
					},
				},
			})
		},
		onViewCategory(item) {
			this.categoryForm.dialog = true
			this.categoryForm.data = item
			this.onView = true
		},
		onOpenUpdateModal(item) {
			// this.categoryForm.dialog = true
			// this.categoryForm.data = item
			// this.onUpdate = true
			this.initCategoryModal({ ...item })
		},
		onAddCategory() {
			this.initCategoryModal()
		},
		onAddSubCategory(category) {
			this.initCategoryModal({
				isMain: 0,
				type: category?.type,
				mainCategoryId: category.id,
			})
		},
		async handleUpdateCategory(item) {
			const data = toFormData(omitEmpties(item), { _method: 'PATCH' })
			let [err] = await this.updateCategory(data)
			if (!err) {
				this.categoryForm.dialog = false
				this.$toast.success(
					this.$t('success-update', [this.$t('category')])
				)
			} else {
				this.$toast.error(this.$t('error-updating', [this.$t('category')]))
			}
		},
		onConfirmDeleteCategory(item) {
			this.confirmDialog.show = true
			this.confirmDialog.category = { ...item }
		},
		async handleCreateCategory(item) {
			let data = toFormData(omitEmpties(item))
			let [err] = await this.createCategory(data)
			if (!err) {
				this.categoryForm.dialog = false
				this.$toast.success(
					this.$t('success-create', [this.$t('category')])
				)
			} else {
				this.$toast.error(this.$t('error-creating', [this.$t('category')]))
			}
		},
		onCloseModal() {
			this.onView = false
			this.onUpdate = false
			this.categoryForm = {
				dialog: false,
				data: {},
			}
			setTimeout(() => {
				this.onView = false
				this.onUpdate = false
			}, 1000)
		},
	},
}
</script>
